<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        For each of the molecules shown below, determine ALL possible intermolecular forces in pure
        samples of the compounds.
      </p>

      <p class="mb-0">a) <chemical-latex :content="molecule1" /></p>

      <div v-for="option in options" :key="'pt-1-' + option.value" class="mb-0 pl-8">
        <v-checkbox v-model="inputs.imf1" :value="option.value" class="d-inline-block">
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-checkbox>
      </div>

      <p class="mt-5 mb-0">b) <chemical-latex :content="molecule2" /></p>

      <div v-for="option in options" :key="'pt-2-' + option.value" class="mb-0 pl-8">
        <v-checkbox v-model="inputs.imf2" :value="option.value" class="d-inline-block">
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-checkbox>
      </div>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question244',
  components: {
    StembleLatex,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        imf1: [],
        imf2: [],
      },
      options: [
        {expression: '$\\text{Dispersion Forces}$', value: 'dispersion'},
        {expression: '$\\text{Dipole-Dipole}$', value: 'dipoleDipole'},
        {expression: '$\\text{Hydrogen Bonding}$', value: 'hbonding'},
        {expression: '$\\text{Ion Dipole}$', value: 'ionDipole'},
        {
          expression: '$\\text{Ion-Induced Dipole}$',
          value: 'ionInducedDipole',
        },
        {
          expression: '$\\text{Dipole-Induced Dipole}$',
          value: 'dipoleInducedDipole',
        },
      ],
    };
  },
  computed: {
    versionVariable() {
      return this.taskState.getValueBySymbol('versionVariable').content;
    },
    versionData() {
      switch (this.versionVariable.value) {
        case 1:
          return {
            molecule1: 'H2N\\text{-}CH2\\text{-}CH2\\text{-}CH3',
            molecule2: 'H3C\\text{-}O\\text{-}CH3',
          };
        case 2:
          return {
            molecule1: 'H3C\\text{-}NH\\text{-}CH2\\text{-}CH3',
            molecule2: 'H3C\\text{-}CH2\\text{-}CH3',
          };
        case 3:
          return {
            molecule1: 'H3C\\text{-}CH2\\text{-}CH2\\text{-}OH',
            molecule2: 'CHCl3',
          };
        case 4:
          return {
            molecule1: 'H3C\\text{-}CH2\\text{-}CH2\\text{-}F',
            molecule2: 'HO\\text{-}CH2\\text{-}CH3',
          };
        case 5:
          return {
            molecule1: 'H3C\\text{-}CH2\\text{-}CH2\\text{-}CH3',
            molecule2: 'H3C\\text{-}CH2\\text{-}O\\text{-}CH2\\text{-}CH3',
          };
        case 6:
          return {
            molecule1: 'H3C\\text{-}CH2\\text{-}CH2\\text{-}NH2',
            molecule2: 'Cl\\text{-}CH2\\text{-}CH3',
          };
        default:
          return {
            molecule1: '',
            molecule2: '',
          };
      }
    },
    molecule1() {
      return this.versionData.molecule1;
    },
    molecule2() {
      return this.versionData.molecule2;
    },
  },
};
</script>
